/* eslint-disable no-console */
import { ApolloLink } from '@apollo/client';
import type { OperationDefinitionNode } from 'graphql';
import getEnv from '~/shared/utils/getEnv';
import isSsr from '~/shared/utils/isSsr';
import formatMessage from './formatMessage';

const getLinkLogger = new ApolloLink((operation, forward) => {
  const startTime = new Date().getTime();
  const HIDE_LINK_LOGS = getEnv('HIDE_LINK_LOGS');
  return forward(operation).map((result) => {
    console.log({
      appRouter: false,
      queryName: operation.operationName,
      variables: JSON.stringify(operation.variables),
    });
    if (process.env.NODE_ENV === 'development' && HIDE_LINK_LOGS !== 'true') {
      const definition = operation.query
        .definitions[0] as OperationDefinitionNode;
      const type = definition.operation;
      const variables = operation.variables;
      const name = operation.operationName;
      const ellapsed = new Date().getTime() - startTime;

      if (isSsr()) {
        console.log({
          [type]: name,
          ellapsed: `${ellapsed} ms`,
          variables: JSON.stringify(variables),
        });
      } else {
        const group = formatMessage(type, name, ellapsed);
        console.groupCollapsed(...group);
        console.log('NAME:', name);
        console.log('VARIABLES:', variables);
        console.log('DATA:', result.data);
        if (type !== 'subscription') {
          console.log('TIME:', `${ellapsed} ms`);
        }
        console.groupEnd();
      }
    }
    return result;
  });
});

export default getLinkLogger;
