import { createHttpLink } from '@apollo/client';
import getAuthLink from './getAuthLink';

type Props = Omit<ApolloLinkOptions, 'isPrivate'> & { uri: string };

const getHttpLink = ({
  requestId,
  forwardedFor,
  uri,
  context,
  token,
  testScenarios,
}: Props) => {
  const httpLink = getApolloHttpLink(uri);
  const authLink = getAuthLink({
    context,
    token,
    requestId,
    forwardedFor,
    testScenarios,
  });
  return authLink.concat(httpLink);
};

const getApolloHttpLink = (uri = '') => createHttpLink({ uri });

export default getHttpLink;
